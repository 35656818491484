import { useState, useCallback } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'config/firebase'; // Ajusta la ruta según tu proyecto
import { InitPaymentStripe } from 'types';

// Define la interfaz para la respuesta interna de la Cloud Function
interface PaymentIntentResponseData {
  clientSecret: string;
  paymentIntentId: string;
}

interface StripePaymentIntentResponse {
  status: string;
  success: boolean;
  message: string;
  data: PaymentIntentResponseData;
}

interface UseStripePaymentIntentResult {
  initPaymentIntent: (props: InitPaymentStripe) => Promise<StripePaymentIntentResponse | null>;
  loading: boolean;
  error: string | null;
  clientSecret: string | null;
  paymentIntentId: string | null;
}

const useStripePaymentIntent = (): UseStripePaymentIntentResult => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);

  const initPaymentIntent = useCallback(async (props: InitPaymentStripe): Promise<StripePaymentIntentResponse | null> => {
    setLoading(true);
    setError(null);
    try {
      const stripeInitPaymentIntent = httpsCallable(functions, 'stripeInitPaymentIntent');
      const response = await stripeInitPaymentIntent(props);
      const data = response.data as StripePaymentIntentResponse;
      if (data.success) {
        setClientSecret(data.data.clientSecret);
        setPaymentIntentId(data.data.paymentIntentId);
      } else {
        throw new Error(data.message || 'Error al crear el PaymentIntent.');
      }
      setLoading(false);
      return data;
    } catch (err: any) {
      setError(err.message || 'Error al iniciar el pago');
      setLoading(false);
      return null;
    }
  }, []);

  return { initPaymentIntent, loading, error, clientSecret, paymentIntentId };
};

export default useStripePaymentIntent;
