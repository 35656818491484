
import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import styled from "styled-components";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {THEME} from "constants/theme/theme";


const StyledInput = styled(TextField)`
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    & input {
      font-family: 'Montserrat', sans-serif;
    }

    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      display: none;
    }
    & .MuiInputBase-root {
      font-family: 'Montserrat', sans-serif;
      width: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & .MuiFormHelperText-root {
      font-size: 11px;
      margin-left: 25px;
      color: #ff0000;
      position: absolute;
      bottom: 0;
      right: 0;
    }
`;

interface IInputProps {
  type: React.HTMLInputTypeAttribute;
  value?: any | undefined;
  placeholder?: string | undefined;
  name?: string;
  label: string;
  error?: boolean | undefined;
  helperText?: string | undefined | any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined | any;
  className?: string | undefined;
  select?: boolean | undefined;
  style?: React.CSSProperties | undefined;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void | undefined;
  disabled?: boolean | undefined;
  pattern?: string;
  RightIcon?: React.ElementType | undefined;
  prefix?: string | undefined;
  step?: string | undefined;
  maxLength?: number | undefined;
  backgroundWhite?: boolean;
  marginBottom?: string;
  formatCardNumber?: boolean;
}

const Input = (props: IInputProps) => {
  const {
    type,
    name,
    label,
    error,
    helperText,
    onChange,
    onBlur,
    value,
    className,
    placeholder,
    select,
    style,
    disabled,
    RightIcon,
    prefix,
    step,
    maxLength,
    backgroundWhite,
    marginBottom,
    formatCardNumber,
  } = props;

  const labelProps = {
    style: {
      color: "#797A7C",
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const formatCardNumberHandler = (value: string) => {
    return value
      .replace(/\D/g, "") // Eliminar caracteres no numéricos
      .replace(/(.{4})/g, "$1 ") // Insertar un espacio cada 4 dígitos
      .trim(); // Eliminar espacio adicional al final
  };
  

  return (
    <StyledInput
      style={{
        width: style?.width || "100%",
        maxWidth: style?.maxWidth || "100%",
        height: style?.height || "54px",
        backgroundColor: backgroundWhite ? "#FFFFFF" : (disabled ? "#F3F3F3" : "transparent"), // Aplicar fondo blanco si backgroundWhite es true
        marginBottom: marginBottom || "0px",
        cursor: disabled ? "not-allowed" : "auto",
        border: disabled
          ? "border: 1px solid rgba(145, 158, 171, 0.2)"
          : "0.1px solid rgba(251, 251, 251, 0.2)",
        ...style,
      }}
      onBlur={onBlur}
      prefix={prefix}
      className={className}
      value={value ? (formatCardNumber ? formatCardNumberHandler(value) : value) : ""}
      type={type === "password" && !showPassword ? "password" : "text"}
      name={name}
      label={label}
      step={step}
      error={error}
      helperText={helperText}
      onChange={(e) => {
        if (maxLength && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
        } else {
          onChange && onChange(e);
        }
      }}
      placeholder={placeholder && placeholder}
      InputProps={{
        endAdornment:
          type === "password" ? (
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={handleTogglePassword}
              onMouseDown={(e) => e.preventDefault()}
              size="small"
              style={{ transform: "scale(0.9)" }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ) : RightIcon ? (
            <img 
              style={{
                color: disabled ? THEME.colors.gray80 : THEME.colors.primary,
                width: "30px",
              }}
              src={RightIcon as any}
              alt="Right Icon"
            />
          ) : null,
      }}
      InputLabelProps={{ ...labelProps }}
      select={select && select}
      disabled={disabled}
      multiline={type === "textarea" ? true : false}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
            height: "110%",
            borderRadius: "10px",
            border: "2px solid #3A36DB",
          },
        },
      }}
    />
  );
};

export default Input;