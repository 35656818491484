import styled from "styled-components";
import topBack from "assets/topBack.png";

export const SmallSection = styled.div`
  clear: both;
  background: ${(props) => props.background || "#ffffff"};
  justify-content: space-between;
  padding: ${(props) => props.pav} ${(props) => props.pah};
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
`;
export const Container = styled.div`
  width: 100%;
`;

export const ContainerTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 992px) {
    flex-direction: column-reverse;
    .hide-mobile {
      display: none;
    }
  }
`;

export const ContainerBar = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px) {
  }
`;
export const CarImg = styled.img`
  width: 100%;
  padding: 3rem;
  @media only screen and (max-width: 600px) {
    padding: 1rem;
  }
`;
export const TopSection = styled.div`
  width: 100%;
  padding-bottom: 10rem;
  background-image: linear-gradient(
      180deg,
      rgba(58, 54, 219, 0.5) 0%,
      rgba(38, 36, 148, 0.5) 100%
    ),
    url(${topBack});
  background-repeat: no-repeat;
  margin-bottom: -10rem;
  @media only screen and (max-width: 600px) {
  }
`;

export const BannerW = styled.div`
  display: block;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    display: none;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
`;

export const BannerM = styled.div`
  display: none;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    display: block;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 1rem;
  background: #ffffff;
  margin-bottom: ${(props) => props.mbottom || 0};
  border-radius: 1rem 0 0 1rem;
  width: ${(props) => props.size || "80%"};
  height: ${(props) => props.sizeV || "100%"};
  justify-content: center;
  padding: 1rem;
  float: right;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 0;
    float: none;
    width: 100%;
    padding: 0.5rem 0;
  }
`;

export const CategoriesWrap = styled.div`
  text-align: center;
  width: 50%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
export const HalfImg = styled.img`
  width: 50%;
  object-fit: contain;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
export const Categories = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  padding: 1rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const Category = styled.div`
  font-family: Outfit, monospace;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #e3f1fe;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 2px rgba(2, 64, 154, 0.16);
  @media only screen and (max-width: 600px) {
    font-size: 0.625rem;
    font-weight: 500;
  }
`;

export const Inmediate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;
export const Titles = styled.h2`
  text-align: ${(props) => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${(props) => props.weight || "700"};
  font-size: ${(props) => props.size || "2.5rem"};
  @media only screen and (max-width: 992px) {
    font-size: 1.25rem;
    text-align: ${(props) => props.alignM || "left"};
  }
`;

export const BText = styled.h2`
  text-align: center;
  font-family: Outfit, monospace;
  font-weight: 800;
  font-size: 3rem;
  @media only screen and (max-width: 992px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 600px) {
    text-align: left;
    font-size: 1.25rem;
  }
`;
export const SText = styled.h2`
  text-align: left;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.25rem;
  @media only screen and (max-width: 600px) {
    font-size: 0.875rem;
  }
`;
export const ServicesTitle = styled.h2`
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.75rem;
  @media only screen and (max-width: 992px) {
    text-align: ${(props) => props.alignM || "left"};
  }
  @media only screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

export const OptionTitle = styled.h2`
  text-align: left;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.25rem;
  @media only screen and (max-width: 992px) {
    text-align: ${(props) => props.alignM || "left"};
  }
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    font-family: Montserrat, monospace;
  }
`;

export const InmediateTitle = styled.h2`
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.75rem;
  @media only screen and (max-width: 992px) {
    font-size: 1.25rem;
    text-align: center;
  }
`;

export const Text768 = styled.p`
  margin: 0;
  font-family: ${(props) => props.family};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  @media only screen and (max-width: 600px) {
    font-family: Montserrat, monospace;
    font-size: 1rem;
  }
`;

export const SpanMiddle = styled.span`
  vertical-align: middle;
`;
export const Subtitles = styled.h3`
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1.25rem;
  @media only screen and (max-width: 600px) {
    font-size: 0.6rem;
  }
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: ${(props) => props.pav || "1rem"} ${(props) => props.pah || "1rem"};
  justify-content: space-between;
  align-items: ${(props) => props.align || "stretch"};
  width: 100%;
  background: ${(props) => props.background || "#ffffff"};
  text-align: center;
`;

export const ProductSlide = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f6;
  line-height: 1rem;
  text-align: left;
  margin-right: 0.5rem;
  padding: 0.5rem;

  &:before {
    ${({ contenido }) =>
      contenido === "Ofertas" &&
      `
    background: #F72C1E;
    `}
    ${({ contenido }) =>
      contenido === "Liquidación" &&
      `
    background: #F1851F;
    `}
    ${({ contenido }) =>
      contenido === "Descuento" &&
      `
    background: #038A25;
    `}
    content: '${(props) => props.contenido}';
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: 0.5rem;
    left: 0;
  }
`;
export const ProductImg = styled.img`
  width: ${(props) => props.size || "100%"};
  padding: ${(props) => props.pad || "0"};
  border-radius: 0.5rem;
  object-fit: cover;
`;
export const ProductDesc = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: #0c121d;
`;
export const SecurePayment = styled.div`
  display: flex;
  color: #fdfdfe;
  background: #3a36db;
  width: 100%;
  gap: 3rem;
  padding: 2rem 4rem;
  align-items: center;
  border-radius: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    padding: 1rem;
  }
`;

export const SecureInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0.5rem;
  }
`;

export const SecureInfoTitle = styled.h2`
  text-align: left;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.75rem;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    font-family: Montserrat, monospace;
    font-size: 1.25rem;
  }
`;
export const SecureInfoDesc = styled.h3`
  text-align: left;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.375rem;
  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
    font-family: Montserrat, monospace;
  }
`;
export const SecureImgWrap = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.4rem;
  }
`;
export const SecureImg = styled.img`
  width: 80%;
  @media only screen and (max-width: 768px) {
    width: 50%;
  }
`;
export const SecureImgTitle = styled.h2`
  text-align: ${(props) => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${(props) => props.weight || "700"};
  font-size: ${(props) => props.size || "2.5rem"};
  @media only screen and (max-width: 768px) {
    width: 40%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.625rem;
    font-weight: 500;
    font-family: Montserrat, monospace;
  }
`;
export const Options = styled.div`
  display: flex;
  color: #ffffff;

  background: #ffffff;
  border-radius: 1rem;
  height: ${(props) => props.sizeV || "100%"};
  width: ${(props) => props.size || "80%"};
  margin-bottom: 0;
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }
`;
export const Option = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 2rem 1rem;
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: ${(props) => props.align || "stretch"};
  transform: translateX(-1rem);
  margin-right: -1rem;
  width: ${(props) => props.size || "152%"};
  background: #f1851f;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Montserrat, monospace;
  }
`;
export const Services = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    width: 75%;
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
  }
  @media only screen and (min-width: 1200px) {
    width: 80%;
  }
`;
export const Service = styled.div`
  display: flex;
  width: 25%;
  box-shadow: 0 4px 20px 10px rgba(2, 64, 154, 0.2);
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 50%;
    margin-bottom: 15%;
  }
`;
export const ServiceImg = styled.img`
  transform: translateY(-50%);
  margin-bottom: -40%;
  align-self: center;
`;

export const ServiceTitle = styled.div`
  background: #0089ff;
  font-weight: 700;
  font-size: 0.875rem;
  color: #ffffff;
  width: 90%;
  height: 100%;
  border-radius: 1rem;
  line-height: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transform: translateY(1rem);
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const ServiceDesc = styled.div`
  background: #ffffff;
  color: #0c121d;
  padding: 2rem 1rem 1rem 1rem;
  width: 90%;
  border-radius: 0 0 1rem 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
export const Subtitle = styled.p`
  font-weight: ${(props) => props.weight || "700"};
  font-size: ${(props) => props.size || ".8rem"};
  text-align: ${(props) => props.linea || "left"};
  color: ${(props) => props.color || "#0C121D"};
`;

export const STipo = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  @media only screen and (max-width: 600px) {
    font-size: 0.625rem;
  }
`;

export const SDestino = styled.p`
  font-weight: 700;
  font-size: 0.875rem;
  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const SLink = styled.a`
  display: block;
  cursor: pointer;
  text-align: center;
  color: #3a36db;
  font-weight: 700;
  @media only screen and (max-width: 600px) {
    font-size: 0.875rem;
  }
`;

export const SponsorProduct = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #fdfdfe;
  line-height: 1.3rem;
  text-align: left;
  margin-right: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;

  &:before {
    ${({ contenido }) =>
      contenido &&
      `
    background: #F72C1E;
    `}
    content: '${(props) => props.contenido}';
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: 0.5rem;
    left: 0;
  }
`;

export const SponsorImg = styled.img`
  width: ${(props) => props.size || "100%"};
  border-radius: 0.5rem;
  object-fit: cover;
  margin-bottom: 0.5rem;
`;
export const SponsorPrice = styled.span`
  color: #038a25;
  font-weight: 700;
  font-size: 0.6rem;
`;

export const SponsorTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;
export const SponsorDesc = styled.span`
  padding: 0.4rem 0;
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 14.63px;
`;
export const TransButton = styled.button`
  display: block;
  width: ${(props) => props.size || "auto"};
  font-size: 0.8rem;
  background: ${(props) => props.background || "#ffffff"};
  color: ${(props) => (props.background === "#3A36DB" ? "#F5F5F6" : "#3A36DB")};
  border-radius: 50px;
  padding: 0.4rem 1rem;
  border: 1px solid #406385;
  font-weight: 700;
`;
export const SponsorBuy = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  display: flex;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`;
export const Browser = styled.div`
  background: #dddfe8;
  border-radius: 1rem;
  border-top: 2rem solid #0c121d;
  border-left: 0.5rem solid #0c121d;
  border-right: 0.5rem solid #0c121d;
  border-bottom: 1rem solid #0c121d;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    position: absolute;
    content: "■ ■ ■";
    color: #ffffff;
    top: -2rem;
    left: 1rem;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const Link = styled.a`
  color: #3a36db;
  font-size: 1rem;
  font-weight: 700;
  @media only screen and (max-width: 600px) {
  }
`;

export const LinkServices = styled.a`
  color: #3a36db;
  font-size: 1rem;
  font-weight: 700;
`;

export const LinkPEI = styled.a`
  color: #3a36db;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const BannerSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 600px) {
  }
`;
export const BannerChild1 = styled.div`
  width: 66%;
  @media only screen and (max-width: 600px) {
    width: 50%;
    float: left;
  }
`;
export const BannerTitle = styled.h2`
  display: block;
  text-align: left;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 2.5rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const BannerDesc = styled.h3`
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1.375rem;
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 600px) {
    font-weight: 400;
    font-size: 0.75rem;
    font-family: Montserrat, monospace;
    line-height: 0.9375rem;
  }
`;
export const BannerChild2 = styled.div`
  width: 30%;
  @media only screen and (max-width: 768px) {
    width: 50%;
    font-size: 0.6rem;
    float: right;
  }
`;

export const BannerChildImg = styled.img`
  width: 100%;
  float: right;
  @media only screen and (max-width: 992px) {
  }
`;
export const BrowserTitle = styled.div`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const BTitleSpan = styled.span`
  color: #3a36db;
`;
export const BrowserDesc = styled.div`
  font-weight: 700;

  width: 25%;
  text-align: left;
  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
  }
`;

export const BrowserImg = styled.div`
  width: 25%;
  text-align: right;
  margin-right: 0.5rem;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const BrowserImg2 = styled.img`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;
export const SponsorAd = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #fdfdfe;
  line-height: 1.3rem;
  text-align: left;
  margin-right: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;

  &:before {
    ${({ contenido }) =>
      contenido &&
      `
    background: #038A25;
    `}
    font-size: .625rem;
    font-weight: 500;
    content: "${(props) => props.contenido}";
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: 0.5rem;
    left: 0;
  }

  &:after {
    content: "♥";
    padding: 0.5rem;
    font-size: 2rem;
    color: #3a36db;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const SponsorAdImg = styled.img`
  width: ${(props) => props.size || "100%"};
  border-radius: 0.5rem;
  object-fit: cover;
  margin-bottom: 0.5rem;
`;
export const SponsorAdPrice = styled.span`
  color: #0c121d;
  font-weight: bold;
  font-size: 1rem;
`;
export const SponsorAdLocation = styled.span`
  color: #262494;
  font-weight: 400;
  font-size: 0.625rem;
`;

export const SponsorAdTitle = styled.div`
  font-weight: 700;
  font-size: 1rem;
  padding: 0.4rem 0;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    font-size: 0.875rem;
  }
`;
export const SponsorAdDesc = styled.span`
  padding: 0.4rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  @media only screen and (max-width: 600px) {
    font-size: 0.625rem;
  }
`;
export const SponsorAdBuy = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerTopServices = styled.div`
  width: 100%;
  background: #e7edf5;

  @media only screen and (min-width: 600px) {
    padding: 1rem;
  }
`;
export const BannerText = styled.p`
  text-align: left;
  font-family: Outfit, monospace;
  font-size: 1.2rem;
  font-weight: 450;
  margin-top: 18px;
  line-height: 1.2;
  margin: 0 auto 15px 20px;
  @media only screen and (max-width: 600px) {
    width: 90%;
    text-align: left;
    margin: 0 auto 18px auto;
  }
`;
export const Button = styled.button`
  /* Estilos base */
  width: 100%;
  background-color: yellow;
  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;

// grid two columns
export const GridAds = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 1rem; */

  @media (max-width: 992px) {
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
  }
`;
