import styled, {css} from "styled-components";
// import StepThree from "./steps/step-three";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FDFDFE;
`

export const DataContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem auto;
  justify-content: center;
  gap: 2rem;
  width: 90%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`
export const PreviousSteps = styled.div`
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
export const PreviousStep = styled.div`
  background: #F5F5F6;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
`

export const PreviousStepDataContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const PreviousStepData = styled.div`
  display: flex;
  flex-direction: column;
`


export const PreviousStepDataStep = styled.span`
  ${props => props.theme.typography.body.lg.medium};
`

export const PreviousStepDataDesc = styled.span`
  ${props => props.theme.typography.label.xs.regular};
  color:#406385;
`
export const Step2Container = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 2rem auto;
  justify-content: center;
  width: 80%;
  @media only screen and (max-width: 768px) {
   /* width: 80%; */
  }
`

export const StepTitle = styled.p`
  ${props => props.theme.typography.body.lg.medium};
  text-align: center;
  margin: 1.5rem 0;
  
  @media only screen and (max-width: 520px) {

  }
`

export const InputLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0;
  color: #000000;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #ffffff;
`

export const Info = styled.small`
  color: #406385;
  ${props => props.theme.typography.label.xs.regular};
  line-height: 12.19px;
  margin: 10px 32px;
`

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "80%"};
  z-index: 1;
  margin-bottom: -10px;

  @media only screen and (max-width: 768px) {
   width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%; 
  background-color: #FDFDFE; 
  align-self: center;
  border-radius: 16px;
  padding: 3%;

  @media (max-width: 992px) {
    width: 100%;
    padding: 3% 0%;
    justify-content: center;
  }
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  margin: ${props => props.margin || "0 auto"};
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  font-weight: 600;
`


export const ShipmentCheckContainer = styled.div`
  display: flex;
  margin: 0 1.5rem;
  align-items: center;
  vertical-align: middle;
`


export const Select = styled.select`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Farrowdown.webp?alt=media&token=7dd41691-6faf-42d6-a14b-59247ee56365');
  background-position: 98%;
  background-repeat: no-repeat;
  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`


export const TextArea = styled.textarea`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const Paragraph = styled.p`
  ${props => props.theme.typography.body.md.regular};
  text-align: center;
  margin: 0;
`

export const ShipmentContainer = styled.form`
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  width: 100%;
 
  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

`
interface ShipmentProps {
  isAddressListEmpty: boolean
}

export const CreateAddressFormContainer = styled.div<ShipmentProps>`
  width: ${(props) => (props.isListAddressEmpty ? "75%" : "50%")};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &:nth-child(1) {
    ${(props) =>
      !props.isListAdressEmpty &&
      css`
        border-right: 1px solid #9ba1aa;
      `}
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    &:nth-child(1) {
      border-right: 0;
      border-bottom: 1px solid #9ba1aa;
    }
  }
`;

export const Methods = styled.form`
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    width: 90%;
  }

  @media only screen and (max-width: 768px) {
    align-self: center;
  }


`

export const StepThreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 768px) {

  }

  @media only screen and (max-width: 520px) {
    /* width: 90%; */
  }

`

export const DirTitle = styled.span`
  ${props => props.theme.typography.body.sm.regular};
  font-size: 1.5rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    margin-top: 1.5rem;
  }
`
export const Payment = styled.div`
  width: 100%;
  border: 1px solid #69727F;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  justify-content: space-between;
  margin: 1rem 0rem;

  &:nth-child(3) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const PaymentImg = styled.img`
  width: 5%;
  @media only screen and (max-width: 1200px) {
    width: 10%;
  }
  @media only screen and (max-width: 768px) {
    width: 10%;
  }
`

export const SummaryTitle = styled.h2`
  text-align: center;
  color: #102746;
  margin-bottom: 1rem;
  ${props => props.theme.typography.heading.sm.bold};
`

export const SummarySubTitle = styled.p`
  text-align: center;
  ${props => props.theme.typography.label.xs.medium};
`
export const SubtitleSpan = styled.span`
  color: #F1851F;
`

export const Summary = styled.div`
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
export const Item = styled.div`
  background: #F5F5F6;
  display: flex;
  padding: .5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 2px rgba(2, 64, 154, 0.16);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
export const ItemImg = styled.img`
  width: 60px;
  height: 60px;

`
export const ItemDesc = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 90%;
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
`

export const ItemQty = styled.span`
  ${props => props.theme.typography.body.sm.bold};
`
export const ItemName = styled.span`
  ${props => props.theme.typography.body.sm.regular};
`
export const ItemPrice = styled.span`
  width: 10%;
  
  ${props => props.theme.typography.body.sm.bold};
  @media only screen and (max-width: 1200px) {
    width: 15%;
  }
`
export const SumFinalText = styled.span`
  color: #0C121D;
  ${props => props.theme.typography.subheading.sm.bold};
`
export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SummaryText = styled.span`
  color: #406385;
  ${props => props.theme.typography.body.lg.medium};
`
export const SummarySubtotal = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const SummaryFinalPrice = styled.span`
  ${props => props.theme.typography.subheading.sm.bold};
  color: #0C121D;
`
export const SummaryTotal = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
`

export const FinalFooter = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 70%;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`
export const FooterItem = styled.div`
  width: 25%;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`
export const FooterItemTitle = styled.span`
  ${props => props.theme.typography.subheading.sm.bold};
`
export const FooterItemDesc = styled.p`
  ${props => props.theme.typography.body.sm.regular};
`
export const FooterItemSpan = styled.span`
  margin-right: 1rem;
  ${props => props.theme.typography.body.sm.bold};
`


interface ButtonNavsProps {
  width?: string
}

export const ButtonsNavsContainer = styled.div<ButtonNavsProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${props => props.width || "90%"};
  margin: 0 auto;
  margin-top: 2rem;

  @media only screen and (max-width: 768px) {
    /* width: 80%; */
  }
`

export const Error = styled.p`
  color: #F1851F;
  ${props => props.theme.typography.body.sm.regular};
  margin: 8px 0px 10px 0px;
`;

// STEP 2

export const AddressesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;
  padding: 0rem .5rem;
`

interface IContainerActionsForm {
  isEditing: boolean
}

export const ContainerActionsForm = styled.div<IContainerActionsForm>`
  display: flex;
  width: 80%;
  justify-content: ${props => props.isEditing ? "space-between" : "center"};
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const SwitchContainer = styled.div`
  width: 80%;
  padding-top: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`