import styled from 'styled-components';
import {THEME} from 'constants/theme/theme';

export const Container = styled.div`
    width: 94%;
    max-width: 800px;
    border: 1px solid #69727F;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 1rem;
    align-self: center;

    @media (max-width: 993px) {
        width: 85%;
        align-self: center;
        gap: 1rem;
    }

    @media (max-width: 520px) {
        width: 100%;
        padding: 4px 1rem;
    }
`;

export const Subcontainer = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 993px) {
        width: 100%;
        justify-content: center;
    }
`

interface IRow {
    width?: string
    justifyContent?: string
    alignItems?: string
    margin?: string
    padding?: string
    flexDirection?: string
    flexWrap?: string
    flex?: string
    height?: string
    border?: string
    borderRadius?: string
    backgroundColor?: string
    color?: string
    fontSize?: string
}

export const Row = styled.div<IRow>`
    width: ${({ width }) => width || '100%'};
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
    flex: ${({ flex }) => flex || '0 1 auto'};
    height: ${({ height }) => height || 'auto'};
    border: ${({ border }) => border || 'none'};
    border-radius: ${({ borderRadius }) => borderRadius || '0'};
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    color: ${({ color }) => color || '#000'};
    font-size: ${({ fontSize }) => fontSize || '1rem'};
`

export const DataCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 0px 16px 0;
    gap: 8px;
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 1rem 0;
`

// CARD COMPONENT

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    padding: 1rem 1rem 0rem 0rem;
    height: 15rem;
    border: 1px solid #69727F;
    border-radius: 1rem;
    max-width: 500px;
    background-color: #fbfbfb;

    @media (max-width: 1024px) {
        height: 14rem;
        max-width: 400px;
        align-self: center;
    }

    @media (max-width: 520px) {
        height: 12rem;
    }

    @media (max-width: 380px) {
        height: 10rem;
    }

    // sombras
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);



`

export const NameInCard = styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin: 0rem 0 0.5rem 1rem;

    @media (max-width: 520px) {
        font-size: .9rem;
    }
`

export const CardNumber = styled.div`
    font-size: 1.3rem;
    font-weight: 700;
    color: ${THEME.colors.gray100};
    margin: 0 0 0rem 1rem;

    @media (max-width: 520px) {
        font-size: 1.2rem;
        margin-bottom: -.5rem;
    }

    @media (max-width: 380px) {
        font-size: 1.1rem;
    }

    @media (max-width: 280px) {
        font-size: .9rem;
    }
`

export const ExpirationDate = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    margin: 0 0 0.5rem 1rem;

    @media (max-width: 520px) {
        font-size: .8rem;
    }

    @media (max-width: 380px) {
        font-size: .7rem;
    }
`

export const CVV = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    margin: 0 0 0.5rem 1rem;

    @media (max-width: 520px) {
        font-size: .8rem;
    }

    @media (max-width: 380px) {
        font-size: .7rem;
    }
`
export const TypeCard = styled.div`
    font-size: 1rem;
    font-weight: 300;
    color: #000;
    margin: 0 0 0rem 1rem;

    @media (max-width: 520px) {
        font-size: .8rem;
    }
`

export const LogoCreditCard = styled.img`
    width: 60px;
    margin: 0.5rem;

    @media (max-width: 520px) {
        width: 50px;
    }

    @media (max-width: 380px) {
        width: 40px;
    }

    // animacione da la vuelta 360 cuando aparece por primera vez
    animation: rotate 1s;
    
    
`;

export const LabelCard = styled.label`
    font-size: .8rem;
    font-weight: 500;
    color: #000;
    margin: 0 0 0.5rem 1rem;

    @media (max-width: 520px) {
        margin: 0 0 0 1rem;
        font-size: .7rem;
    }

    @media (max-width: 380px) {
        font-size: .6rem;
    }
`