import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from "dayjs";
import { BackButton } from 'ui/Buttons';
import { Methods, StepTitle, ButtonsNavsContainer, StepThreeContainer } from "../styled";
import { RadioGroup } from "@mui/material";
import ModalLoader from "ui/ModalLoader/modal-loader";
import CreditCard from "views/containers/Payments/CreditCard";
import { totalCart, itemsCart as cartItems } from 'redux/cart';
import { setPaymentMethod, getPaymentMethod, getAddress } from 'redux/checkout';
import { userSelector, offlineUserInfoSelector } from "redux/user";
import { getCountry, getLanguage, getCurrency } from "redux/common";
import { useTranslation } from "react-i18next";
import useCreateOrder from "hooks/checkout/order/use-create-order";
import { useNotification } from "libs/context/AlertContext";
import { STEPS_CHECKOUT } from "../constants";
import { PaymentMethodIdEnum, StatusOrderEnum } from "constants/constants";
import { BREAKPOINTS } from "constants/index";
import { getLocalISO8601 } from "utils/time/get-local-iso8601";
import useStripePaymentIntent from "hooks/checkout/stripe/use-stripe-init-payment/use-stripe-init-payment";

interface StepThreeProps {
    onSubmit: (step: number) => void;
    onBack: () => void;
    setData: (data: any) => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const StepThree = ({ onSubmit, onBack, setData }: StepThreeProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotification();
    const { createOrder, error: errorCreateOrder, response: responseCreateOrder } = useCreateOrder();
    const paymentMethod = 'card' //useSelector(getPaymentMethod);
    const itemsCart = useSelector(cartItems);
    const totalAmount = parseFloat(useSelector(totalCart).toFixed(2));
    const address = useSelector(getAddress);
    const userData = useSelector(userSelector).userData;
    const offlineUserInfo = useSelector(offlineUserInfoSelector);
    const country = useSelector(getCountry);
    const language = useSelector(getLanguage);
    const currency: string = useSelector(getCurrency);
    const currentDateTime = getLocalISO8601();

    const { firstName, firstSurname, email, phone } = userData || offlineUserInfo;

    interface IOrderCreatedIds {
        orderUidFirestore: string;
        orderUidPostgre: number;
    }

    const [dataOrder, setDataOrder] = useState<IOrderCreatedIds | null>(null);
    const [dataSuccessPayment] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    // Integración del custom hook para inicializar el PaymentIntent
    const {
        initPaymentIntent,
        loading: initPaymentLoading,
        error: initPaymentError,
        clientSecret,
        paymentIntentId,
    } = useStripePaymentIntent();

    // Cuando el método de pago es "card" y aún no se tiene el clientSecret, se inicializa el PaymentIntent
    useEffect(() => {
        if (paymentMethod === "card" && !clientSecret) {
            // Llamamos a la función pasando el monto y otros parámetros (aquí solo usamos monto y moneda)
            initPaymentIntent({
                amount: totalAmount, // Nuestro backend se encargará de la conversión a centavos
                currency,
                description: 'Pago de productos y servicios whatdoyouneed7',
            });
        }
    }, [paymentMethod, clientSecret, totalAmount, currency, initPaymentIntent]);

    // Funciones de manejo de orden y pago (sin cambios respecto a tu código original)
    const handleCreateOrder = ({ paymentMethodId, paymentTransactionId = null }) => {
        setLoading(true);
        if (paymentTransactionId) {
            createOrder({
                userUid: userData.uid,
                items: itemsCart,
                totalAmount,
                orderType: "product_payment",
                paymentMethodId,
                country,
                currency,
                status: StatusOrderEnum.paid,
                orderDate: currentDateTime,
                paymentTransactionId,
                addressShippingId: address?.id,
            });
        } else {
            createOrder({
                userUid: userData.uid,
                items: itemsCart,
                totalAmount,
                orderType: "product_payment",
                paymentMethodId,
                country,
                currency,
                status: StatusOrderEnum.pending,
                orderDate: currentDateTime,
                paymentTransactionId,
                addressShippingId: address?.id,
            });
        }
    };

    const handleFinishAndSetData = (data: any) => {
        setData((prev: any) => ({
            ...prev,
            itemsCart,
            paymentMethod,
            dataSuccessPayment,
            totalAmount,
            shippingCost: 0,
            taxAmount: 0,
            date: dayjs().format(),
            id: data?.orderUidPostgre || 'error',
            uid: data?.orderUidFirestore || 'error',
        }));

        showSuccess(t('order_placed_successfully'));
        onSubmit(STEPS_CHECKOUT.RESUME_CHECKOUT);
    };

    const handlePaymentSuccessfulWithCreditCard = (data: any) => {
        showSuccess(t('payment_successful_thank_you'));
        handleCreateOrder({
            paymentMethodId: PaymentMethodIdEnum.card,
            paymentTransactionId: data.paymentTransactionId,
        });
    };

    useEffect(() => {
        if (errorCreateOrder) {
            showError(t('error_during_payment_check_data'));
            setLoading(false);
        }
        if (responseCreateOrder) {
            if (responseCreateOrder.status === 'ERROR') {
                showError(t('error_during_payment_check_data'));
            } else {
                setLoading(false);
                setDataOrder(responseCreateOrder.data as IOrderCreatedIds);
                handleFinishAndSetData(responseCreateOrder.data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorCreateOrder, responseCreateOrder]);

   // Mientras se obtiene el clientSecret (inicializando el PaymentIntent), mostramos un loader
    if (paymentMethod === "card" && !clientSecret) {
        return <ModalLoader isLoading={initPaymentLoading || loading} />;
    }

    return (
        // Se pasa el clientSecret obtenido a las opciones de <Elements>
        <Elements stripe={stripePromise} options={{ clientSecret: clientSecret  } as any}>
            <StepThreeContainer>
                <Methods>
                    <StepTitle>{t('choose_payment_method')}</StepTitle>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="card"
                        name="payment"
                    >
                        <CreditCard
                            clientSecret={clientSecret as any}
                            onBeginPayment={() => { }}
                            onSuccessfulPayment={(e) => {
                                console.log('onSuccessfulPayment', e);
                                setTimeout(() => {
                                    handlePaymentSuccessfulWithCreditCard(e);
                                }, 1000);
                            }}
                            onFailedPayment={() => {
                                showError(t('error_during_payment_check_data'));
                                setLoading(false);
                            }}
                            // setChecked={() => {
                            //     dispatch(setPaymentMethod("card"));
                            // }}
                            checked={paymentMethod === "card"}
                            setChecked={() => setPaymentMethod("card")}
                            // infoTransaction={{
                            //     amount: totalAmount,
                            //     description: `${t('credit_card_payment_for_products_services_needed')}`,
                            //     email,
                            //     firstName,
                            //     firstSurname,
                            //     phone,
                            //     concept: `${t('online_payment_for_products_services_needed')}`,
                            //     taxAmount: 0,
                            //     lang: language,
                            // }}
                        />
                    </RadioGroup>
                </Methods>

                <ButtonsNavsContainer width={window.innerWidth < BREAKPOINTS.lg ? '90%' : '80%'}>
                    <BackButton onClick={onBack} text={`${t('back')}`} />
                </ButtonsNavsContainer>
                <ModalLoader isLoading={loading} />
            </StepThreeContainer>
        </Elements>
    );
};

export default StepThree;
