import { Container, FlexColumn, Titles } from "./styled";
import Skeleton from "@mui/material/Skeleton";
import { SponsorBuy } from "../../pages/yacht/components/Interest/Yacht";
import Carousel from "react-multi-carousel";
import ExtraCard from "components/cards/services/extraServices/extra-services-card";
// import { BREAKPOINTS } from "constants/index";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setServiceDetail } from "modules/services/store";
import { redirectToDetail } from "./main";
import { useTranslation } from "react-i18next";

export const Extras = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { data, hasRedirectToDetail } = props;
  const ItemSkeletons = [1, 2, 3, 4];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  return (
    <Container background="#E7EDF5" pav="2rem">
      <FlexColumn background="#E7EDF5" pav={"2rem"}>
        <div
          className={
            "d-flex justify-content-between align-items-center w-100 mb-2"
          }
        >
          <Titles alignM={"center"} size={"1.5rem"}>
            {t("you_may_also_be_interested_in_these_services")}
          </Titles>
          {/* {window.innerWidth > BREAKPOINTS.lg && <Link href={"/services/business"}>Ver todas</Link>} */}
        </div>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={true}
        >
          {data !== null
            ? data.map((item: any, index: number) => {
                return (
                  <ExtraCard
                    key={index}
                    onClick={() => {
                      dispatch(setServiceDetail(item));
                      redirectToDetail(
                        item,
                        hasRedirectToDetail,
                        navigate,
                        location.pathname
                      );
                    }}
                    textButton={`${t("view_more")}`}
                    data={item}
                  />
                );
              })
            : ItemSkeletons.map((item) => {
                return (
                  <div key={item}>
                    <div>
                      <Skeleton
                        variant={"rounded"}
                        width={"95%"}
                        height={200}
                      />
                      <Skeleton variant={"text"} width={"20%"} />
                      <Skeleton variant={"text"} width={"60%"} />
                      <Skeleton variant={"text"} width={"100%"} />
                      <SponsorBuy>
                        <Skeleton
                          variant={"rounded"}
                          width={"25%"}
                          height={25}
                        />
                        <Skeleton
                          variant={"rounded"}
                          width={"25%"}
                          height={25}
                        />
                      </SponsorBuy>
                    </div>
                  </div>
                );
              })}
        </Carousel>
      </FlexColumn>
    </Container>
  );
};
