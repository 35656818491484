import React, { useState } from "react";
import { useStripe, useElements, CardElement, PaymentElement } from "@stripe/react-stripe-js";
import { Container, Row, ButtonContainer, Subcontainer } from "./styled"
import { Button } from "ui/Buttons";
import Checkbox from "ui/Checkbox";
import './styles.css';


function getCustomStatusPayment(status: string) {
  switch (status) {
    case 'succeeded':
      return 'paid';
    case 'processing':
      return 'pending';
    case 'requires_payment_method':
      return 'pending';
    case 'requires_confirmation':
      return 'pending';
    case 'canceled':
      return 'canceled';
    case 'requires_action':
      return 'pending';
    default:
      return 'pending';
  }
}

interface CreditCardProps {
  clientSecret: string; // Client secret del PaymentIntent
  onBeginPayment?: () => void;
  onSuccessfulPayment: (data: { paymentTransactionId: string, statusPayment: string, headerCodeStatus: number, date: string, description: string, totalPayed: number, operationType: string, cardType: string }) => void;
  onFailedPayment?: (error: any) => void;
  checked: boolean;
  setChecked: () => void;
}

const CreditCard: React.FC<CreditCardProps> = ({
  clientSecret,
  onBeginPayment,
  onSuccessfulPayment,
  onFailedPayment,
  checked,
  setChecked,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe no se ha cargado correctamente.");
      return;
    }

    if (!clientSecret) {
      setError("No se ha recibido el Client Secret correctamente.");
      return;
    }

    if (onBeginPayment) onBeginPayment();

    setLoading(true);
    setError(null);


    try {
      console.log("🔹 Iniciando confirmación de pago...");

      const { error: confirmError, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });


      console.log("🔹 Respuesta de confirmCardPayment:", { confirmError, paymentIntent });

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      if (error) {
        throw new Error(error);
      } else {
        alert("Pago exitoso");

        onSuccessfulPayment({
          paymentTransactionId: paymentIntent.id,
          statusPayment: getCustomStatusPayment(paymentIntent.status),
          headerCodeStatus: 200,
          date: new Date().toISOString(),
          description: "Pago productos y servicios - whatdoyouneed7",
          totalPayed: paymentIntent.amount / 100,
          operationType: "payment",
          cardType: paymentIntent.payment_method_types[0],
        })
      }

    } catch (err: any) {
      console.error("❌ Error en el pago:", err.message);
      setError(err.message || "Error al confirmar el pago.");
      onFailedPayment?.(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row justifyContent="space-between" style={{ width: "100%", marginTop: '.5rem' }}>
        <Checkbox
          checked={checked}
          label="Tarjeta de crédito"
          onClick={setChecked}
        />
      </Row>
      {
        checked ? (<div style={{ width: "100%", }}>
          <Subcontainer>
            <form onSubmit={handleSubmit} style={{ width: "100%" }} className="card-form-stripe">
              <PaymentElement  />
              {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
              < ButtonContainer>
                <Button type="button" disabled={!stripe || loading || !clientSecret} style={{ marginTop: "20px" }} onClick={handleSubmit as any}>
                  Pagar
                </Button>
              </ButtonContainer>
            </form>
          </Subcontainer>
        </div>) : null
      }
    </Container>
  );
};

export default CreditCard;
